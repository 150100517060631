import useCms from './use-cms'

export default function useServerTime() {
  const { config } = useCms()

  const now = computed(
    () =>
      Date.now() +
      Math.floor(
        new Date(config.value.serverTime).getTime() - new Date().getTime()
      )
  )

  return {
    now,
  }
}
