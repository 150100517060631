<template>
  <div id="layout" class="isolate">
    <main id="main" class="relative">
      <MessageController />
      <div class="relative flex min-h-full flex-col">
        <slot />
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
const { initialise } = useWidgetMode()

if (import.meta.client) {
  initialise()
}

defineOptions({
  name: 'WidgetLayout',
})
</script>
