import type { Movie } from '#gql/default'

export default async function useMovieList({
  fetchOperation,
  variables,
}: {
  fetchOperation:
    | 'FetchMovieListForMovieCards'
    | 'FetchMovieListForMovieCarousel'
    | 'FetchMovieListForMovieList'
  variables: {
    id: string
    limit: number
  }
}) {
  const { data, status } = await useAsyncGql(fetchOperation, variables)

  const pending = computed(() => status.value === 'pending')
  const movies = computed(() => data.value.movieList?.movies as Movie[])

  return {
    movies,
    pending,
  }
}
