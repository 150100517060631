import type { FormKitTypeDefinition, FormKitNode } from '@formkit/core'
import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  textInput,
  $attrs,
} from '@formkit/inputs'
import { inlineSvg } from '@forms/formkit/sections'

export const numberInputSchema: FormKitTypeDefinition = {
  schema: outer(
    wrapper(
      label('$label'),
      inner(
        {
          $el: 'button',
          attrs: {
            type: 'button',
            class: '$classes.button',
            'data-decrement': true,
            disabled: '$disableDecrement',
            onClick: '$handlers.decrement',
          },
          children: [inlineSvg('$decrementIcon', '$classes.icon')],
        },
        $attrs(
          {
            inputmode: 'numeric',
            pattern: '[0-9]*',
            max: '$max',
            min: '$min',
          },
          textInput()
        ),
        {
          $el: 'button',
          attrs: {
            type: 'button',
            class: '$classes.button',
            'data-increment': true,
            disabled: '$disableIncrement',
            onClick: '$handlers.increment',
          },
          children: [inlineSvg('$incrementIcon', '$classes.icon')],
        }
      )
    ),
    help('$help'),
    messages(message('$message.value'))
  ),
  type: 'input',
  family: 'text',
  props: {
    min: { default: 0 },
    max: { default: 100 },
    step: { default: 1 },
    deletable: { boolean: true, default: false },
  },

  forceTypeProp: 'number',
  features: [addHandlers],
  schemaMemoKey: '5ucev8ajxri',
}

function addHandlers(node: FormKitNode) {
  node.on('created', () => {
    let isUpdating = false

    function canDecrement() {
      const currentValue = Number(node._value) || 0
      const min = Number(node.props.min) || 0
      const step = Number(node.props.step) || 1
      return !node.props.disabled && currentValue - step >= min
    }

    function canIncrement() {
      const currentValue = Number(node._value) || 0
      const max = Number(node.props.max) || Infinity
      const step = Number(node.props.step) || 1
      return !node.props.disabled && currentValue + step <= max
    }

    function updateButtonStates() {
      const currentValue = Number(node._value) || 0
      const min = Number(node.props.min) || 0
      const max = Number(node.props.max) || Infinity
      const step = Number(node.props.step) || 1

      node.context!.disableDecrement = !canDecrement()
      node.context!.disableIncrement = !canIncrement()

      const newDecrementIcon =
        node.props.deletable && currentValue - step <= min
          ? 'trash-can-outline'
          : 'minus'
      const newIncrementIcon = 'plus'

      if (
        node.context!.decrementIcon !== newDecrementIcon ||
        node.context!.incrementIcon !== newIncrementIcon
      ) {
        node.context!.decrementIcon = newDecrementIcon
        node.context!.incrementIcon = newIncrementIcon
        node.store.set(node.props.id + '.schema', node.schema)
      }
    }

    function updateValue(step: number) {
      if (isUpdating) return
      isUpdating = true

      const currentValue = Number(node._value) || 0
      const newValue = currentValue + step
      const min = Number(node.props.min) || 0
      const max = Number(node.props.max) || Infinity

      if (newValue >= min && newValue <= max) {
        node.input(newValue)
      } else if (newValue < min && node.props.deletable) {
        node.input(null)
      } else if (newValue < min) {
        node.input(min)
      } else if (newValue > max) {
        node.input(max)
      }

      updateButtonStates()
      isUpdating = false
    }

    function increment() {
      if (canIncrement()) {
        updateValue(Number(node.props.step) || 1)
      }
    }

    function decrement() {
      if (canDecrement()) {
        updateValue(-(Number(node.props.step) || 1))
      }
    }

    node.context!.handlers.increment = increment
    node.context!.incrementIcon = 'plus'
    node.context!.decrementIcon = 'minus'
    node.context!.handlers.decrement = decrement

    // Initial update to set button states and icons correctly
    updateButtonStates()

    // Watch for changes in value, min, max, step, deletable, or disabled props
    node.on('input', (value) => {
      if (isUpdating) return
      isUpdating = true

      const min = Number(node.props.min) || 0
      const max = Number(node.props.max) || Infinity
      const step = Number(node.props.step) || 1

      if (value === '' || value === null || isNaN(Number(value))) {
        node.input(min)
      } else {
        const numValue = Number(value)
        const newValue = Math.max(
          min,
          Math.min(max, Math.round((numValue - min) / step) * step + min)
        )
        if (newValue !== numValue) {
          node.input(newValue)
        }
      }
      updateButtonStates()
      isUpdating = false
    })

    node.on('prop:min', updateButtonStates)
    node.on('prop:max', updateButtonStates)
    node.on('prop:step', updateButtonStates)
    node.on('prop:deletable', updateButtonStates)
    node.on('prop:disabled', updateButtonStates)
  })
}
