import { default as _91_46_46_46pageSlug_93kr94DXTb5oMeta } from "/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/cms/pages/[...pageSlug].vue?macro=true";
import { default as activatevFBFDWvGRlMeta } from "/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/activate.vue?macro=true";
import { default as indexzIlN8hffL9Meta } from "/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/index.vue?macro=true";
import { default as _91securityKey_93eT7JDOYs9fMeta } from "/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as newlhVNUQ9wyWMeta } from "/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/password/new.vue?macro=true";
import { default as watchlistdA9J4KsNLVMeta } from "/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/watchlist.vue?macro=true";
import { default as indexLg2bZFPepBMeta } from "/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/index.vue?macro=true";
import { default as infos40efGI04eYMeta } from "/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/infos.vue?macro=true";
import { default as programzScREOQZHPMeta } from "/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/program.vue?macro=true";
import { default as _91showSlug_93K6jk4S9rrOMeta } from "/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue?macro=true";
import { default as _91movieSlug_93CagMKbhsu1Meta } from "/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug].vue?macro=true";
export default [
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/cms/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: _91movieSlug_93CagMKbhsu1Meta?.name,
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___de",
    path: "/film/:movieSlug/filminfos",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___de",
    path: "/film/:movieSlug/spielzeiten",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___de",
    path: "/film/:movieSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93CagMKbhsu1Meta?.name,
    path: "/es/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___es",
    path: "infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___es",
    path: "program",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___es",
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93CagMKbhsu1Meta?.name,
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___de",
    path: "/film/:movieSlug/filminfos",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___de",
    path: "/film/:movieSlug/spielzeiten",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___de",
    path: "/film/:movieSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93CagMKbhsu1Meta?.name,
    path: "/es/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___es",
    path: "infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___es",
    path: "program",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___es",
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93CagMKbhsu1Meta?.name,
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___de",
    path: "/film/:movieSlug/filminfos",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___de",
    path: "/film/:movieSlug/spielzeiten",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___de",
    path: "/film/:movieSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93CagMKbhsu1Meta?.name,
    path: "/es/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___es",
    path: "infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___es",
    path: "program",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___es",
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93CagMKbhsu1Meta?.name,
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___de",
    path: "/film/:movieSlug/filminfos",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___de",
    path: "/film/:movieSlug/spielzeiten",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___de",
    path: "/film/:movieSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91movieSlug_93CagMKbhsu1Meta?.name,
    path: "/es/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___es",
    path: "infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___es",
    path: "program",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-show-showSlug___es",
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-11-14_15-19-26_5970f26/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m)
  }
]
  }
]