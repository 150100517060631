export default {
  global: {
    form: 'w-full',
    icon: 'formkit-icon pointer-events-none size-5 fill-current',
    suffixIcon: 'formkit-suffix-icon',
    help: 'formkit-help',
    message: 'formkit-message',
    messages: 'formkit-messages',
    input: 'formkit-input',
    wrapper: 'formkit-wrapper',
    label: 'formkit-label',
    legend: 'formkit-legend',
    actions: 'formkit-actions',
    inner: 'formkit-inner',
    decorator: 'formkit-decorator inline-flex',
    charCount: 'formkit-char-count text-right',
    button: 'formkit-button',
  },

  'family:text': {
    outer: 'formkit-family-text',
  },

  'family:box': {
    outer: 'formkit-family-box',
    wrapper: 'formkit-wrapper-family-box',
    inner: 'formkit-inner-family-box',
    input: 'formkit-input-family-box',
    label: 'formkit-label-family-box',
  },

  search: {
    outer: 'formkit-search',
  },

  number: {
    outer: 'formkit-number',
  },

  select: {
    outer: 'formkit-select',
  },

  color: {
    outer: 'formkit-color',
  },

  textarea: {
    outer: 'formkit-textarea',
    inner: 'formkit-inner-textarea',
    input: 'formkit-input-textarea scrollbar-themed',
  },

  checkbox: {
    decorator: 'formkit-decorator-checkbox',
  },

  mask: {
    outer: 'formkit-mask',
    input: 'formkit-input-mask',
  },

  radio: {
    decorator: 'formkit-decorator-radio',
  },
}
