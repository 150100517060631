export default function useSkeleton() {
  const shouldAnimate = ref(process.client)

  onUnmounted(() => {
    shouldAnimate.value = true
  })

  const cssClasses = {
    color: 'bg-neutral-light-1 dark:bg-neutral-dark-1',
    text: 'h-2 rounded-lg',
    image: 'h-full w-full',
    divideColor: 'divide-neutral-light-1 dark:divide-neutral-dark-1',
  }

  return {
    shouldAnimate,
    cssClasses,
  }
}
