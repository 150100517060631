import { useScriptTag } from '@vueuse/core'

type DataLayer = Array<{ [key: string]: any }>

declare global {
  interface Window {
    dataLayer: DataLayer
  }
}

interface GoogleTagManager {
  push: (item: any) => void
  trackPageView: () => void
  get: (key: string) => any | null
}

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()

  const { getConsentForVendor, getVendorForDomain } = useConsentManager()
  const vendorKey = getVendorForDomain({
    domain: 'www.googletagmanager.com',
    pick: 'id',
  })
  const hasConsent = computed(() => getConsentForVendor(vendorKey as string))

  const scriptSrc = computed(
    () => `${runtimeConfig.public.GTM_URL}?id=${runtimeConfig.public.GTM_ID}`
  )

  const { load, unload, scriptTag } = useScriptTag(scriptSrc, () => {}, {
    async: true,
    defer: true,
    manual: true,
  })

  function trackPageView() {
    const route = useRoute()
    const getRouteBaseName = useRouteBaseName()

    push({
      event: 'page_view',
      content_group: getRouteBaseName(route),
    })
  }

  function push(payload: any) {
    const cleanValue = deepUnref(payload)
    window.dataLayer.push(cleanValue)
  }

  function getMostRecentValue(dataLayer: DataLayer, key: string) {
    const foundItem = dataLayer
      .slice()
      .reverse()
      .find((item) => key in item)
    return foundItem ? foundItem[key] : null
  }

  function get(key: string): any | null {
    return getMostRecentValue(window.dataLayer, key)
  }

  async function loadTagManager() {
    if (
      import.meta.client &&
      !scriptTag.value &&
      hasConsent.value &&
      !!runtimeConfig.public.GTM_ID
    ) {
      await load()
    }
  }

  nuxtApp.hook('app:mounted', () => {
    const { config } = useCms()
    const { getVendors, getConsentForPurpose, userConsent } =
      useConsentManager()

    push({
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
    })

    push({
      [DATA_LAYER_KEYS.GA4_ID]: runtimeConfig.public.GA4_ID,
      [DATA_LAYER_KEYS.CONFIG_ID]: config.value?.id,
      [DATA_LAYER_KEYS.CONFIG_NAME]: config.value?.name,
      [DATA_LAYER_KEYS.APP_TYPE]: runtimeConfig.public.GA4_APP_TYPE,
      [DATA_LAYER_KEYS.GA4_ID_ADDITIONAL]:
        config.value?.googleAnalyticsId ?? undefined,
      [DATA_LAYER_KEYS.GOOGLE_ADS_CONVERSION_ID]:
        config.value?.googleAdsConversionId ?? undefined,
      [DATA_LAYER_KEYS.GOOGLE_ADS_CONVERSION_LABEL]:
        config.value?.googleAdsConversionLabel ?? undefined,
      [DATA_LAYER_KEYS.FB_PIXEL_ID]: config.value?.fbPixelId ?? undefined,
      [DATA_LAYER_KEYS.CONSENT_REQUIRED]: getVendors({
        category: CONSENT_MANAGER_PURPOSE.REQUIRED,
        pick: 'id',
      }),
      [DATA_LAYER_KEYS.CONSENT_FUNCTIONAL]: getVendors({
        category: CONSENT_MANAGER_PURPOSE.FUNCTIONAL,
        pick: 'id',
      }),
      [DATA_LAYER_KEYS.CONSENT_ADVERTISING]: getVendors({
        category: CONSENT_MANAGER_PURPOSE.ADVERTISING,
        pick: 'id',
      }),
    })

    push({
      event: DATA_LAYER_EVENTS.USER_CONSENT_DEFAULT,
      [DATA_LAYER_KEYS.USER_CONSENT]: userConsent.value?.join(','),
      [DATA_LAYER_KEYS.USER_CONSENT_FUNCTIONAL]: getConsentForPurpose(
        CONSENT_MANAGER_PURPOSE.FUNCTIONAL
      )
        ? 'granted'
        : 'denied',
      [DATA_LAYER_KEYS.USER_CONSENT_ADVERTISING]: getConsentForPurpose(
        CONSENT_MANAGER_PURPOSE.ADVERTISING
      )
        ? 'granted'
        : 'denied',
    })

    watch(
      hasConsent,
      async (isConsentGiven) => {
        if (isConsentGiven) {
          loadTagManager()
        }

        if (!isConsentGiven && scriptTag.value) {
          await unload()
        }
      },
      { immediate: true }
    )
  })

  return {
    provide: {
      gtm: <GoogleTagManager>{
        push,
        get,
        trackPageView,
      },
    },
  }
})
