import type { FormKitTypeDefinition } from '@formkit/core'
import {
  outer,
  wrapper,
  help,
  messages,
  message,
  localize,
  ignores,
} from '@formkit/inputs'
import Button from '@base/components/button.vue'

export const submitButtonSchema: FormKitTypeDefinition = {
  schema: outer(
    messages(message('$message.value')),
    wrapper({
      $cmp: markRaw(Button),
      props: {
        text: '$label || $ui.submit.value',
        type: 'submit',
      },
    }),
    help('$help')
  ),
  type: 'input',
  family: 'button',
  props: [],
  forceTypeProp: 'submit',
  features: [localize('submit'), ignores],
  schemaMemoKey: 'ea6j7j3bnm6',
}
