import type { FormKitNode, FormKitPlugin } from '@formkit/core'
import { useRouteQuery } from '@vueuse/router'
import { useRoute, useRouter } from 'vue-router'
import type { LocationQueryRaw } from 'vue-router'
import { undefine } from '@formkit/utils'

export interface PersistInUrlOptions {
  useAsDefault?: boolean
  allowTypes?: string[]
}

export function createPersistInUrlPlugin(
  PersistInUrlOptions?: PersistInUrlOptions
): FormKitPlugin {
  return (node: FormKitNode) => {
    node.addProps(['persistInUrl'])

    const allowTypes = PersistInUrlOptions?.allowTypes || ['input']

    const usePersistInUrl =
      undefine(node.props.persistInUrl) ||
      node.props.persistInUrl === 'true' ||
      node.props.persistInUrl === true ||
      PersistInUrlOptions?.useAsDefault === true

    if (usePersistInUrl && allowTypes.includes(node.type)) {
      const route = useRoute()
      const router = useRouter()
      const urlValues = useRouteQuery(node.name)

      node.on('created', () => {
        if (!urlValues.value) {
          return
        }

        node.input(
          urlValues.value.includes(',')
            ? (urlValues.value as string).split(',')
            : urlValues.value
        )
      })

      node.on('settled', () => {
        const value = Array.isArray(node.value)
          ? node.value.length
            ? node.value.join(',')
            : undefined
          : node.value ?? undefined

        router.replace({
          query: {
            ...route.query,
            [node.name]: value,
          } as LocationQueryRaw,
        })
      })
    }
  }
}
