<template>
  <CatalogMovieList
    v-bind="catalogProps"
    :movie-list-id
    :data-block-id
    :data-block-type
    :layout="movieListLayout"
  />
</template>

<script setup lang="ts">
import useCmsCatalogConfig from '@cms/composables/use-cms-catalog-config'
import type { LinkParam } from '#gql/default'

export interface CmsBlockMovieListProps {
  id: string
  __typename: 'CmsMovieListBlock'
  layout: MovieListLayout
  movieListId: string
  linkParams: LinkParam[]
  hideLink: boolean
  hideDate: boolean
  infinite: boolean
  autoplay: boolean
  slideDuration: number
  itemsPerPage: number
  renderAboveTheFold: boolean
  showFallbackResults?: boolean
}

const props = defineProps<CmsBlockMovieListProps>()
const { dataBlockId, dataBlockType, catalogProps } = useCmsCatalogConfig(props)
const movieListLayout = computed(() => props.layout as MovieListLayout)

defineOptions({
  name: 'CmsBlockMovieList',
})
</script>
