<template>
  <div class="relative flex flex-col">
    <div class="relative aspect-[2/1]">
      <div :class="[cssClasses.image, cssClasses.color]" />
    </div>
  </div>
</template>

<script setup lang="ts">
const { cssClasses } = useSkeleton()

defineOptions({
  name: 'CarouselSkeleton',
})
</script>
