<template>
  <LazyMessageModal
    v-if="message.type === MESSAGE_TYPE.MODAL"
    :message
    @close="remove(message)"
  />

  <LazyMessageToast v-else :message @closed="remove(message)" />
</template>

<script setup lang="ts">
interface Props {
  message: Message
}

defineProps<Props>()

const { remove } = useMessage()

defineOptions({
  name: 'Message',
})
</script>
