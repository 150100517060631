<template>
  <Html :lang="head?.htmlAttrs?.lang" :dir="head?.htmlAttrs?.dir">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <ConsentManager />
    <LazyShowGroupsExpressBooking v-if="isExpressBookingEnabled" :cinema />
  </Html>
</template>

<script setup lang="ts">
import type { Link } from '@unhead/vue'
import type { Cinema } from '#gql/default'

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

const route = useRoute()
const { config } = useCms()

if (!config.value) {
  showError({
    statusCode: 404,
    statusMessage: 'configNotFound',
  })
} else {
  const link: Link[] = [
    {
      rel: 'canonical',
      href: `https://${config.value?.defaultDomain}${route.path}`,
    },
  ]
  if (config.value?.favicon) {
    link.push({
      rel: 'icon',
      type: 'image/x-icon',
      href: getImage(config.value, 'favicon', config.value?.name).src as string,
    })
  }

  useHead({
    link,
    titleTemplate(title) {
      return [title, config.value?.name].filter(Boolean).join(' | ')
    },
  })
}

const getRouteBaseName = useRouteBaseName()
const isExpressBookingEnabled = computed(
  () =>
    config.value?.enableExpressBooking &&
    getRouteBaseName(route) !== ROUTE_NAMES.SHOW
)

const cinema = computed(() => config.value?.cinemas[0] as Cinema)

defineOptions({
  name: 'AppSiteFactory',
})
</script>
