<template>
  <div data-legacy-widget>
    <div :id="eframeId"></div>
  </div>
</template>

<script setup lang="ts">
// We need the eframeId div wrapped with a div
// because the id will be overwritten by the grapesjs id
export interface Props {
  eframeId?: string
  options: EframeOptions
}

const props = withDefaults(defineProps<Props>(), {
  eframeId: 'kinoheld-eframe',
})

useLegacyWidget(props.options)

defineOptions({
  name: 'LegacyWidget',
})
</script>
