import type { FormKitSchemaExtendableSection } from '@formkit/inputs'
import InlineSvg from '@assets/components/inline-svg.vue'

export function inlineSvg(
  iconName = 'circle',
  iconClass = ''
): FormKitSchemaExtendableSection {
  return {
    $cmp: markRaw(InlineSvg),
    props: {
      name: iconName,
      key: iconName,
      class: iconClass,
    },
  }
}
