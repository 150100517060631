<template>
  <div
    class="py-2.5 text-center text-xs uppercase leading-none"
    v-html="flagsMarkup"
  />
</template>

<script lang="ts" setup>
import type { Flag } from '#gql/default'

interface Props {
  flags: Flag[]
}

const props = defineProps<Props>()

const flagsMarkup = computed(() => {
  return props.flags
    .map((flag: Flag) => flag.name)
    .join('<span class="text-primary"> | </span>')
})

defineOptions({
  name: 'ShowLinkFlags',
})
</script>
