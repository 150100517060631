import useCms from '@cms/composables/use-cms'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { fetchCmsPage, fetchCmsConfig, config } = useCms()

  if (!config.value) {
    await fetchCmsConfig()
  }

  if (import.meta.server || to.path !== from.path) {
    await fetchCmsPage(to.path)
  }
})
