import { createValidationPlugin } from '@formkit/validation'
import {
  email as emailRule,
  required as requiredRule,
  confirm as confirmRule,
  contains_numeric as containsNumericRule,
  contains_symbol as containsSymbolRule,
  contains_uppercase as containsUppercaseRule,
  contains_lowercase as containsLowercaseRule,
  contains_alphanumeric as containsAlphanumericRule,
  length as lengthRule,
  between as betweenRule,
} from '@formkit/rules'

export const validation = createValidationPlugin({
  required: requiredRule,
  confirm: confirmRule,
  contains_numeric: containsNumericRule,
  contains_symbol: containsSymbolRule,
  contains_uppercase: containsUppercaseRule,
  contains_lowercase: containsLowercaseRule,
  contains_alphanumeric: containsAlphanumericRule,
  email: emailRule,
  length: lengthRule,
  between: betweenRule,
})
