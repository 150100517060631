<template>
  <ClientOnly>
    <LazyAdSlot
      v-if="isGooglePublisherTagConsentGiven && isValidSlot"
      v-bind="slotConfig"
      :slot-name
    />
  </ClientOnly>
</template>

<script setup lang="ts">
import useConsentManager from '@base/composables/use-consent-manager'
import { CONSENT_MANAGER_PURPOSE } from '@base/constants/consent-manager'

export interface CmsBlockAdSlotProps {
  id: string
  __typename: 'CmsAdSlotBlock'
  slotName: string
}

const { slotName } = defineProps<CmsBlockAdSlotProps>()

const appConfig = useAppConfig()
const isValidSlot = computed(() => !!appConfig?.adSlots?.[slotName])
const slotConfig = computed(() => appConfig?.adSlots?.[slotName] ?? {})
const { getConsentForPurpose } = useConsentManager()
const isGooglePublisherTagConsentGiven = computed(() =>
  getConsentForPurpose(CONSENT_MANAGER_PURPOSE.ADVERTISING)
)

defineOptions({
  name: 'CmsBlockAdSlot',
})
</script>
