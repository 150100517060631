interface transitionPositionConfig {
  enterFrom: TransitionPositions
  enterTo: TransitionPositions
  leaveTo: TransitionPositions
  withFade?: boolean
}

export default function useTransitionPositions(
  config: transitionPositionConfig
) {
  const enterFromClasses = computed(() => {
    const classes = ['transform']

    classes.push(getClassesByProp(config.enterFrom))

    if (config.withFade) {
      classes.push('opacity-0')
    }

    return classes.join(' ')
  })

  const enterToClasses = computed(() => {
    const classes = ['transform']

    classes.push(getClassesByProp(config.enterTo))

    if (config.withFade) {
      classes.push('opacity-100')
    }

    return classes.join(' ')
  })

  const leaveToClasses = computed(() => {
    const classes = ['transform']

    classes.push(getClassesByProp(config.leaveTo))

    if (config.withFade) {
      classes.push('opacity-0')
    }

    return classes.join(' ')
  })

  function getClassesByProp(position: TransitionPositions): string {
    switch (position) {
      case TRANSITION_POSITIONS.CENTER:
        return 'translate-x-0 translate-y-0'
      case TRANSITION_POSITIONS.LEFT:
        return '-translate-x-full'
      case TRANSITION_POSITIONS.RIGHT:
        return 'translate-x-full'
      case TRANSITION_POSITIONS.BOTTOM:
        return 'translate-y-full'
      case TRANSITION_POSITIONS.TOP:
        return '-translate-y-full'
    }

    return ''
  }

  return {
    enterFromClasses,
    enterToClasses,
    leaveToClasses,
  }
}
