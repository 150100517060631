import type { FormKitSchemaNode, FormKitSectionsSchema } from '@formkit/core'

type SchemaModifier = (
  schema: FormKitSchemaNode,
  extensions: FormKitSectionsSchema
) => FormKitSchemaNode

export function combineSchemaModifications(...modifiers: SchemaModifier[]) {
  return (
    originalSchema: (extensions: FormKitSectionsSchema) => FormKitSchemaNode
  ) => {
    return (extensions: FormKitSectionsSchema) => {
      const combinedExtensions = { ...extensions }

      modifiers.forEach((modifier) => {
        const modifiedSchema = modifier(
          originalSchema(combinedExtensions),
          combinedExtensions
        )
        Object.assign(combinedExtensions, modifiedSchema)
      })

      return originalSchema(combinedExtensions)
    }
  }
}
