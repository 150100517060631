import getUtmParams from '../utils/get-utm-params'

export default defineNuxtRouteMiddleware((to) => {
  const utm = useUtmState()
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign'] as const

  const hasUtmParams = utmParams.some((param) => to.query[param])

  if (!hasUtmParams) {
    return
  }

  try {
    utm.value = getUtmParams(to.query as Record<string, string | undefined>)
  } catch {}
})
