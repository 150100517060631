export default function getUtmParams(
  query: Record<string, string | undefined>
) {
  return ['utm_source', 'utm_medium', 'utm_campaign'].reduce(
    (acc, param) => {
      acc[param] = query[param]
      return acc
    },
    {} as Record<string, string | undefined>
  )
}
