import type { FormKitNode, FormKitPlugin } from '@formkit/core'
import { clone } from '@formkit/utils'
import { combineSchemaModifications } from '@forms/formkit/utils/combine-schema-modifications'

export interface FloatingLabelsOptions {
  useAsDefault?: boolean
}

export function createFloatingLabelsPlugin(
  FloatingLabelsOptions?: FloatingLabelsOptions
): FormKitPlugin {
  const floatingLabelsPlugin = (node: FormKitNode) => {
    node.addProps({
      floatingLabel: {
        boolean: true,
        default: !!FloatingLabelsOptions?.useAsDefault,
      },
    })

    const useFloatingLabels = node.props.floatingLabel

    if (useFloatingLabels && node.context) {
      node.on('created', () => {
        if (!node.props?.definition?.schema || !node.context) {
          return
        }

        const inputDefinition = clone(node.props.definition)
        if (
          node.props.family === 'text' ||
          ['textarea', 'select'].includes(node.props.type)
        ) {
          const originalSchema = inputDefinition.schema
          if (typeof originalSchema !== 'function') {
            return
          }

          inputDefinition.schema = combineSchemaModifications(
            (schema, extensions) => {
              extensions.outer = {
                ...extensions.outer,
                attrs: {
                  ...(extensions.outer?.attrs || {}),
                  'data-floating-label': 'true',
                },
              }
              return schema
            }
          )(originalSchema)

          if (inputDefinition.schemaMemoKey) {
            inputDefinition.schemaMemoKey += '-floating-label'
          }
          node.props.definition = inputDefinition
        }
      })
    }
  }
  return floatingLabelsPlugin
}
