import { BACKEND_TOKEN_QUERY_STRING } from '~/constants/backend-token'

export default defineNuxtRouteMiddleware(async (to, _) => {
  const khUser = (to.query[BACKEND_TOKEN_QUERY_STRING.KHUSER] as string) ?? ''
  const khKey = (to.query[BACKEND_TOKEN_QUERY_STRING.KHKEY] as string) ?? ''

  const { fetchToken } = useBackendToken()

  if (!khUser || !khKey) {
    return
  }

  await fetchToken({
    [BACKEND_TOKEN_QUERY_STRING.KHUSER]: khUser,
    [BACKEND_TOKEN_QUERY_STRING.KHKEY]: khKey,
  })
})
