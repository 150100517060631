<template>
  <div
    :class="containerClassList"
    :data-block-id="id"
    data-block-type="CmsDividerBlock"
  >
    <div class="w-full" :class="separatorClassList" />
  </div>
</template>

<script lang="ts" setup>
import {
  CMS_DIVIDER_LAYOUT,
  CMS_DIVIDER_HORIZONTAL_SPACING,
  CMS_DIVIDER_VERTICAL_SPACING,
  CMS_DIVIDER_SEPARATOR_STYLE,
} from '@cms/constants/cms'
import type {
  CmsDividerLayout,
  CmsDividerHorizontalSpacing,
  CmsDividerVerticalSpacing,
  CmsDividerSeparatorStyle,
} from '@cms/types/cms'

export interface CmsBlockDividerProps {
  id: string
  __typename: 'CmsDividerBlock'
  layout: CmsDividerLayout
  verticalSpacing: CmsDividerVerticalSpacing
  horizontalSpacing: CmsDividerHorizontalSpacing
  separatorStyle: CmsDividerSeparatorStyle
}

const { layout, verticalSpacing, horizontalSpacing, separatorStyle } =
  defineProps<CmsBlockDividerProps>()

const containerClassList = computed(() => {
  const classes = []

  if (verticalSpacing === CMS_DIVIDER_VERTICAL_SPACING.SM) {
    classes.push('py-4')
  } else if (verticalSpacing === CMS_DIVIDER_VERTICAL_SPACING.MD) {
    classes.push('py-8')
  } else if (verticalSpacing === CMS_DIVIDER_VERTICAL_SPACING.LG) {
    classes.push('py-12')
  } else if (verticalSpacing === CMS_DIVIDER_VERTICAL_SPACING.XL) {
    classes.push('py-16')
  }

  if (horizontalSpacing === CMS_DIVIDER_HORIZONTAL_SPACING.SM) {
    classes.push('px-4')
  } else if (horizontalSpacing === CMS_DIVIDER_HORIZONTAL_SPACING.MD) {
    classes.push('px-8')
  } else if (horizontalSpacing === CMS_DIVIDER_HORIZONTAL_SPACING.LG) {
    classes.push('px-12')
  } else if (horizontalSpacing === CMS_DIVIDER_HORIZONTAL_SPACING.XL) {
    classes.push('px-16')
  }

  return classes
})

const separatorClassList = computed(() => {
  const classes = []

  if (separatorStyle === CMS_DIVIDER_SEPARATOR_STYLE.NONE) {
    classes.push('hidden')
  } else if (separatorStyle === CMS_DIVIDER_SEPARATOR_STYLE.XS) {
    classes.push('h-px')
  } else if (separatorStyle === CMS_DIVIDER_SEPARATOR_STYLE.SM) {
    classes.push('h-1')
  } else if (separatorStyle === CMS_DIVIDER_SEPARATOR_STYLE.MD) {
    classes.push('h-2.5')
  } else if (separatorStyle === CMS_DIVIDER_SEPARATOR_STYLE.LG) {
    classes.push('h-4')
  }

  if (layout === CMS_DIVIDER_LAYOUT.PRIMARY) {
    classes.push('bg-primary')
  } else if (layout === CMS_DIVIDER_LAYOUT.SECONDARY) {
    classes.push('bg-secondary')
  } else if (layout === CMS_DIVIDER_LAYOUT.NEUTRAL) {
    classes.push('bg-neutral')
  }

  return classes
})

defineOptions({
  name: 'CmsBlockDivider',
})
</script>
