import type {
  FormKitNode,
  FormKitPlugin,
  FormKitSectionsSchema,
} from '@formkit/core'
import { clone, undefine } from '@formkit/utils'
import CharCount from '@forms/components/char-count.vue'

export interface CharCountOptions {
  useAsDefault?: boolean
  countTypes?: string[]
}

export function createCharCountPlugin(
  CharCountOptions?: CharCountOptions
): FormKitPlugin {
  return (node: FormKitNode) => {
    node.addProps(['charCount', 'charCountCurrent', 'charCountMax'])

    const allowTypes = CharCountOptions?.countTypes || [
      'text',
      'password',
      'textarea',
    ]

    const useCharCount =
      undefine(node.props.charCount) ||
      node.props.charCount === 'true' ||
      node.props.charCount === true ||
      CharCountOptions?.useAsDefault === true

    if (useCharCount) {
      node.on('created', () => {
        if (!node.props || !node.props.definition) return

        const inputDefinition = clone(node.props.definition)
        if (allowTypes.includes(node.props.type)) {
          const originalSchema = inputDefinition.schema
          if (typeof originalSchema !== 'function') return

          node.props.charCountCurrent = 0
          node.props.charCountMax = null

          const higherOrderSchema = (extensions: FormKitSectionsSchema) => {
            extensions.help = {
              if: '$charCount',
              children: [
                {
                  $cmp: markRaw(CharCount),
                  props: {
                    count: '$charCountCurrent',
                    max: '$charCountMax',
                  },
                },
                "$help || ''",
              ],
            }
            return originalSchema(extensions)
          }

          inputDefinition.schema = higherOrderSchema

          if (inputDefinition.schemaMemoKey) {
            inputDefinition.schemaMemoKey += '-char-count'
          }
          node.props.definition = inputDefinition

          function updateCountValue(value: string) {
            node.props.charCountCurrent = value ? value.length : 0
          }

          function updateMaxLength() {
            const rules = node.props.parsedRules
            if (rules && rules.length > 0) {
              const maxLengthRule = rules.find(
                (rule: { name: string; args: object[] }) =>
                  rule.name === 'length' && rule.args && rule.args.length > 1
              )
              if (maxLengthRule) {
                node.props.charCountMax = parseInt(maxLengthRule.args[1])
              } else {
                node.props.charCountMax = undefined
              }
            } else {
              node.props.charCountMax = undefined
            }
          }

          node.on('input', ({ payload }) => updateCountValue(payload as string))
          node.on('prop:parsedRules', () => {
            updateMaxLength()
            updateCountValue(node._value as string)
          })

          // Initialize values
          updateMaxLength()
          if (node._value) {
            updateCountValue(node._value as string)
          }

          // Also update on mounted to ensure it's set after any potential initial value changes
          node.on('mounted', () => {
            updateCountValue(node._value as string)
          })
        }
      })
    }
  }
}
