export default defineNuxtRouteMiddleware((to, from) => {
  const nuxtApp = useNuxtApp()
  const widgetMode = useState('widgetMode', () => false)

  if (!nuxtApp.$isWidgetMode) {
    nuxtApp.provide('isWidgetMode', widgetMode)
  }

  if (to.query.mode === 'widget') {
    setPageLayout('widget')
    // not sure if this is the best place here :)
    useGqlHeaders({ 'x-kinoheld-context': 'widget' })
    widgetMode.value = true
  }

  if (
    import.meta.client &&
    from.query.mode === 'widget' &&
    to.query.mode !== 'widget'
  ) {
    return navigateTo({
      ...to,
      query: {
        ...to.query,
        mode: 'widget',
        design: from.query.design,
      },
    })
  }
})
