<template>
  <div :class="classes">
    {{ displayText }}
  </div>
</template>

<script setup lang="ts">
interface Props {
  count: number
  max?: number
  classes?: string
}

const props = defineProps<Props>()

const { t } = useI18n()

const remainingChars = computed(() => {
  if (props.max !== undefined) {
    return Math.max(0, props.max - props.count)
  }
  return props.count
})

const displayText = computed(() => {
  if (props.max !== undefined) {
    return t('remainingChars', { remaining: remainingChars.value })
  }
  return t('chars', { count: props.count })
})

defineOptions({
  name: 'CharCount',
})
</script>

<i18n>
de:
  remainingChars: "Noch {remaining} Zeichen übrig."
  chars: "{count} Zeichen"
es:
  remainingChars: "Todavía quedan {remaining} caracteres."
  chars: "{count} caracteres"
</i18n>
