<template>
  <CatalogProgram v-bind="programProps" :data-block-id :data-block-type />
</template>

<script setup lang="ts">
import useCms from '@cms/composables/use-cms'
import type { LinkParam, Cinema } from '#gql/default'

export interface CmsBlockProgramProps {
  id: string
  __typename: 'CmsProgramBlock'
  layout: ProgramLayout
  filters: UrlFilter[]
  linkParams: LinkParam[]
  cinemas: string[]
  renderAboveTheFold: boolean
  showFallbackResults: boolean
  bookingModal: boolean
  preserveFilters: boolean
  periods?: string[]
  flags?: string[]
  languageFlags?: string[]
  technologyFlags?: string[]
  eventFlags?: string[]
  miscellaneousFlags?: string[]
  search?: string
  genres?: string[]
  contentRatings?: string[]
  showGroups?: string[]
  auditoriums?: string[]
  itemsPerPage?: number
  enablePagination?: boolean
}

const props = defineProps<CmsBlockProgramProps>()
const { dataBlockId, dataBlockType, programProps } = useProgramConfig(props)

function useProgramConfig(props: CmsBlockProgramProps) {
  const { config } = useCms()
  const { filters, id, cinemas, __typename, showFallbackResults, ...rest } =
    props

  return {
    dataBlockId: id,
    dataBlockType: __typename,
    programProps: computed(() => ({
      ...rest,
      cinemaIds:
        cinemas ?? config.value?.cinemas.map((cinema: Cinema) => cinema.cid),
      fetchFallbackResults: showFallbackResults,
      urlPrefix: id,
      // temporary mapping from date to dates until grapesjs is updated to pass dates instead of date
      filters: filters?.map((filter) =>
        filter === URL_FILTER.DATE ? URL_FILTER.DATES : filter
      ),
    })),
  }
}

defineOptions({
  name: 'CmsBlockProgram',
})
</script>
