import { useElementBounding } from '@vueuse/core'

export default function useWidgetMode() {
  const route = useRoute()
  const { $isWidgetMode } = useNuxtApp()

  const state = useState('widgetMode', () => ({
    isInitialised: false,
    frame: {
      offsetTop: 0,
      scrollTop: 0,
      scrollOffset: 0,
      remainingHeight: 0,
    },
    parent: {
      scrollTop: 0,
      windowHeight: 0,
    },
  }))

  function sendMessage(message: string) {
    window.parent.postMessage(message, '*')
  }

  function calculateVisibleFrame() {
    if (!state.value.frame || !state.value.parent) {
      return {
        visibleTop: 0,
        visibleHeight: 0,
        containerTop: 0,
      }
    }

    const { offsetTop, scrollOffset, remainingHeight } = state.value.frame
    const { scrollTop, windowHeight } = state.value.parent

    const frameTop = offsetTop + scrollOffset
    const visibleTop = Math.max(0, frameTop - scrollTop)
    const visibleHeight = Math.min(windowHeight - visibleTop, remainingHeight)

    return {
      visibleTop,
      visibleHeight,
      containerTop: frameTop - scrollTop,
    }
  }

  function receiveMessage(event: MessageEvent) {
    if (event.data?.topic === 'parent_specs' && event.data.message) {
      state.value = {
        ...state.value,
        frame: event.data.message?.frame,
        parent: event.data.message?.parent,
      }
    }
  }

  function initialise() {
    if (!state.value.isInitialised) {
      const main = document.getElementById('main')
      const { height, width } = useElementBounding(() => main)

      watch([height, width], () => sendMessage(`if_height=${height.value}`), {
        immediate: true,
      })

      watch(
        () => route.fullPath,
        () => sendMessage(`if_scroll=0`),
        {
          immediate: true,
        }
      )

      window.addEventListener('message', receiveMessage, false)

      state.value = {
        ...state.value,
        isInitialised: true,
      }
    }
  }

  const visibleFrame = computed(() => {
    const { visibleTop, visibleHeight, containerTop } = calculateVisibleFrame()
    return {
      ...state.value.frame,
      visibleTop,
      visibleHeight,
      containerTop,
    }
  })

  return {
    initialise,
    frame: visibleFrame,
    parent: computed(() => state.value.parent),
    isWidgetMode: computed(() => $isWidgetMode.value as boolean),
  }
}
