import { bindings, defineFormKitConfig } from '@formkit/vue'
import { createI18nPlugin, de, es } from '@formkit/i18n'
import { createLibraryPlugin, form, group, hidden } from '@formkit/inputs'
import { mask, createProPlugin } from '@formkit/pro'
import { generateClasses, createThemePlugin } from '@formkit/themes'
import { inputs } from '@forms/formkit/config/inputs.js'
import { validation } from '@forms/formkit/config/validation.js'
import { createFloatingLabelsPlugin } from '@forms/formkit/plugins/floating-labels'
import { createCharCountPlugin } from '@forms/formkit/plugins/char-count'
import { createPersistInUrlPlugin } from '@forms/formkit/plugins/persist-in-url'
import CharCount from '@forms/components/char-count.vue'
import theme from './theme.js'

const library = createLibraryPlugin({
  group,
  form,
  hidden,
})

const i18n = createI18nPlugin({
  de,
  es,
})

const themePlugin = createThemePlugin('', {})
const proPlugin = createProPlugin('fk-daf990e47d', {
  mask,
})

export default defineFormKitConfig({
  autoImport: true,
  config: {
    classes: generateClasses(theme),
  },
  components: {
    FormKitCharCount: CharCount,
  },
  plugins: [
    library,
    validation,
    i18n,
    bindings,
    themePlugin,
    proPlugin,
    createFloatingLabelsPlugin(),
    createCharCountPlugin(),
    createPersistInUrlPlugin(),
  ],
  locales: { de, es },
  locale: 'de',
  inputs,
})
