function smartUnref(val: Ref<any> | any): any {
  // Non-ref object?  Go deeper!
  if (val !== null && !isRef(val) && typeof val === 'object') {
    return deepUnref(val)
  }

  return unref(val)
}

function unrefArray(arr: Array<any>): Array<any> {
  return arr.map(smartUnref)
}

const unrefObject = (obj: Record<string, any>): Record<string, any> => {
  const unreffed: Record<string, any> = {}

  // Object? un-ref it!
  Object.keys(obj).forEach((key) => {
    unreffed[key] = smartUnref(obj[key])
  })

  return unreffed
}

export default function deepUnref(val: Ref<any> | any): any {
  const checkedVal = isRef(val) ? unref(val) : val

  if (!(checkedVal !== null && typeof checkedVal === 'object')) {
    return checkedVal
  }

  if (Array.isArray(checkedVal)) {
    return unrefArray(checkedVal)
  }

  return unrefObject(checkedVal)
}
