function isObject(object: any): boolean {
  return object != null && typeof object === 'object'
}

export function deepEqual(object1: any, object2: any): boolean {
  // If both are null or undefined, return true
  if (object1 === object2) {
    return true
  }

  // If either is null or undefined, they are not equal
  if (
    object1 === null ||
    object2 === null ||
    object1 === undefined ||
    object2 === undefined
  ) {
    return false
  }

  // If either is not an object, they are not equal
  if (!isObject(object1) || !isObject(object2)) {
    return false
  }

  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects = isObject(val1) && isObject(val2)
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false
    }
  }

  return true
}
