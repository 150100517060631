import * as Sentry from '@sentry/vue'
import pkg from '../../../package.json'
import { defineNuxtPlugin, useRuntimeConfig } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig()

  Sentry.init({
    enabled: !!runtimeConfig.public.SENTRY_ENABLED,
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    debug: runtimeConfig.public.ENV !== 'production',
    dsn: runtimeConfig.public.SENTRY_DSN,
    release: pkg.version,
    environment: runtimeConfig.public.ENV,
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
  })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  }
})
