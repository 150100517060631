import type { FormKitTypeDefinition } from '@formkit/core'
import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  prefix,
  suffix,
  icon,
  textareaInput,
  initialValue,
} from '@formkit/inputs'

export const textareaInputSchema: FormKitTypeDefinition = {
  schema: outer(
    wrapper(
      label('$label'),
      inner(
        icon('prefix', 'label'),
        prefix(),
        textareaInput(),
        suffix(),
        icon('suffix')
      )
    ),
    help('$help'),
    messages(message('$message.value'))
  ),
  type: 'input',
  props: {
    charCount: {
      boolean: true,
      default: false,
    },
  },
  forceTypeProp: 'textarea',
  features: [initialValue],
  schemaMemoKey: 'nykflic29u',
}
