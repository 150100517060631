import type { FormKitTypeDefinition } from '@formkit/core'
import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  icon,
  prefix,
  suffix,
  textInput,
  casts,
} from '@formkit/inputs'

function createTextInputDefinition(
  forceTypeProp: 'text' | 'email' | 'password' | 'color',
  schemaMemoKey: string
): FormKitTypeDefinition {
  return {
    schema: outer(
      wrapper(
        label('$label'),
        inner(
          icon('prefix', 'label'),
          prefix(),
          textInput(),
          suffix(),
          icon('suffix')
        )
      ),
      help('$help'),
      messages(message('$message.value'))
    ),
    type: 'input',
    family: 'text',
    forceTypeProp,
    features: [casts],
    schemaMemoKey,
  }
}

export const textInputSchema: FormKitTypeDefinition = createTextInputDefinition(
  'text',
  '96k8e005opm'
)
export const emailInputSchema: FormKitTypeDefinition =
  createTextInputDefinition('email', '55myfo06c8c')

export const passwordInputSchema: FormKitTypeDefinition =
  createTextInputDefinition('password', 'k2ivb3903es')

// Add the color input schema
export const colorInputSchema: FormKitTypeDefinition =
  createTextInputDefinition('color', '96kru1mm6rq')
