export default defineNuxtRouteMiddleware(async (to) => {
  const referrer = useReferrerState()

  if (import.meta.server && to.query.ref) {
    await useAffiliate(to.query.ref as string)
  }

  if (
    import.meta.client &&
    !referrer.value &&
    document.referrer &&
    !document.referrer.includes(location.origin)
  ) {
    try {
      const url = new URL(document.referrer)
      referrer.value = url.origin
    } catch {}
  }
})
