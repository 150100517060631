import type { FormKitTypeDefinition, FormKitNode } from '@formkit/core'
import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  selectInput,
  option,
  optionSlot,
  optGroup,
  $if,
  options,
  selects,
} from '@formkit/inputs'
import { inlineSvg } from '@forms/formkit/sections'

export const selectInputSchema: FormKitTypeDefinition = {
  schema: outer(
    wrapper(
      label('$label'),
      inner(
        selectInput(
          $if(
            '$slots.default',
            () => '$slots.default',
            optionSlot(
              $if(
                '$option.group',
                optGroup(optionSlot(option('$option.label'))),
                option('$option.label')
              )
            )
          )
        ),
        inlineSvg('chevron-down', '$classes.icon')
      )
    ),
    help('$help'),
    messages(message('$message.value'))
  ),
  type: 'input',
  props: ['options', 'placeholder', 'optionsLoader'],
  forceTypeProp: 'select',
  features: [options, selects],
  schemaMemoKey: 'zqpwzl4kjdn',
}
